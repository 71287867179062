import React, { useEffect, useState } from 'react';
import AUTH_BASE_URL from '../../../util/AUTH_BASE_URL';
import KanbanBoard from './KanBan';
import Wrapper from "../../global/Wrapper";
import Team from './Team';  // Import the Team component
import TeamStatus from './TeamStatus';
import { DragDropContext } from 'react-beautiful-dnd';
import { getGradientColors } from "../../../util/helpers/getGradientColors";
import { calculateCapacity } from "../../../util/helpers/calculateCapacity"; // Import the capacity calculation function

const Planning = () => {
    const [data, setData] = useState(null);
    const [stateColumnId, setStateColumnId] = useState(null);
    const [assigneeColumnId, setAssigneeColumnId] = useState(null);
    const [uniqueAssignees, setUniqueAssignees] = useState([]);  // Unique assignees
    const [selectedAssignee, setSelectedAssignee] = useState(null);  // State to track the active assignee
    const [columns, setColumns] = useState({});  // Columns for the Kanban board
    const [inProgressTasksCount, setInProgressTasksCount] = useState(0);  // Track in-progress tasks count
    const [editing, setEditing] = useState(false);
    const [currentSprint, setCurrentSprint] = useState(null);
    const [sprintStartDate, setSprintStartDate] = useState(null);
    const [sprintEndDate, setSprintEndDate] = useState(null);

    // Fetch data from the backend
    const fetchData = async () => {
        try {
            const response = await fetch(`${AUTH_BASE_URL}projects/planning/data`, {
                method: 'GET',
            });

            if (!response.ok) {
                throw new Error('Network response was not ok');
            }

            const result = await response.json();
            setData(result);

            const stateColumn = result.columns.find(col => col.title.toLowerCase().includes('state'));
            const assigneeColumn = result.columns.find(col => col.title.toLowerCase().includes('assignee'));
            const effortColumn = result.columns.find(col => col.title.toLowerCase().includes('level of effort'));
            const complexityColumn = result.columns.find(col => col.title.toLowerCase().includes('complexity'));
            const endColumn = result.columns.find(col => col.title.toLowerCase().includes('end'));
            setStateColumnId(stateColumn?.id);
            setAssigneeColumnId(assigneeColumn?.id);

            const dynamicColumns = {};
            result.columns.forEach(col => {
                dynamicColumns[col.id] = { title: col.title, options: col.options, state: col.state || [] };
            });
            setColumns(dynamicColumns);

            const uniqueAssigneesList = getUniqueAssignees(result.rows, assigneeColumn?.id, effortColumn?.id, stateColumn?.id, endColumn?.id, complexityColumn?.id);
            setUniqueAssignees(uniqueAssigneesList);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    useEffect(() => {
        const calculateCurrentSprint = () => {
            const today = new Date(); // Current date
            let currentYear = today.getFullYear();

            // Get the first sprint start date in the current year
            let firstSprintStartDate = getFirstSprintStartDate(currentYear);

            // If today is before the first sprint start date, use the previous year
            if (today < firstSprintStartDate) {
                currentYear = currentYear - 1;
                firstSprintStartDate = getFirstSprintStartDate(currentYear);
            }

            // Calculate the difference in milliseconds
            const diffTime = today - firstSprintStartDate;

            // Calculate the difference in days
            const diffDays = Math.floor(diffTime / (1000 * 60 * 60 * 24));

            // Calculate the number of sprints since the first sprint start date
            const sprintsSince = Math.floor(diffDays / 14);

            // Calculate the current sprint's start date
            const currentSprintStart = new Date(
                firstSprintStartDate.getTime() + sprintsSince * 14 * 24 * 60 * 60 * 1000
            );

            // Calculate the current sprint's end date
            const currentSprintEnd = new Date(
                currentSprintStart.getTime() + 14 * 24 * 60 * 60 * 1000
            );

            // Update the state
            setCurrentSprint(sprintsSince + 1); // Sprint numbers start at 1
            setSprintStartDate(currentSprintStart);
            setSprintEndDate(currentSprintEnd);
        };

        const getFirstSprintStartDate = (year) => {
            const janFirst = new Date(year, 0, 1); // January 1st of the given year
            const dayOfWeek = janFirst.getDay(); // getDay() returns 0 (Sunday) to 6 (Saturday)

            // Wednesday is day 3 (0=Sunday, 1=Monday, 2=Tuesday, 3=Wednesday)
            let daysUntilWednesday = (3 - dayOfWeek + 7) % 7; // number of days until next Wednesday

            const firstSprintStartDate = new Date(year, 0, 1 + daysUntilWednesday);
            return firstSprintStartDate;
        };

        calculateCurrentSprint();
    }, []);

    useEffect(() => {
        if (data && uniqueAssignees.length > 0) {
            const updatedAssignees = uniqueAssignees.map(assignee => ({
                ...assignee,
                capacity: calculateCapacity(assignee), // Recalculate capacity for each assignee
            }));

            // Only update state if there's a change in assignees
            const isDifferent = JSON.stringify(updatedAssignees) !== JSON.stringify(uniqueAssignees);
            if (isDifferent) {
                setUniqueAssignees(updatedAssignees);
            }
        }
    }, [data, uniqueAssignees]); // Trigger when data or assignees change


    useEffect(() => {
        fetchData();
    }, []);

    const getUniqueAssignees = (tasks, assigneeColumnId, effortColumnId, stateColumnId, endColumnId, complexityColumnId) => {
        const uniqueAssignees = new Map();  // Use Map to store assignee-specific data

        tasks.forEach(task => {
            const assigneeCell = task.cells.find(cell => cell.columnId === assigneeColumnId);
            const stateCell = task.cells.find(cell => cell.columnId === stateColumnId);

            if (assigneeCell && assigneeCell.value && stateCell && stateCell.value.toLowerCase() === 'in progress') {
                const assignees = assigneeCell.value.split(',').map(assignee => assignee.trim());

                assignees.forEach(assignee => {
                    if (!uniqueAssignees.has(assignee)) {
                        uniqueAssignees.set(assignee, {
                            name: assignee,
                            effortColumn: effortColumnId,
                            endColumn: endColumnId,
                            complexityColumn: complexityColumnId,
                            tasks: []  // Initialize an array to store tasks
                        });
                    }

                    const assigneeData = uniqueAssignees.get(assignee);
                    assigneeData.tasks.push(task);  // Add the current task to the assignee's task list
                    uniqueAssignees.set(assignee, assigneeData);  // Update the assignee's entry in the Map
                });
            }
        });

        // Convert Map to Array and return
        return Array.from(uniqueAssignees.values());
    };

    // Calculate in-progress tasks count for the selected assignee
    const calculateInProgressTasks = () => {
        if (!data || !data.rows || !columns) return;

        const stateColumnId = Object.keys(columns).find(columnId => columns[columnId].title === "State");
        const assigneeColumnId = Object.keys(columns).find(columnId => columns[columnId].title === "Assignee");

        const inProgressTasks = data.rows.filter(row => {
            const stateCell = row.cells.find(cell => cell.columnId === parseInt(stateColumnId));
            const assigneeCell = row.cells.find(cell => cell.columnId === parseInt(assigneeColumnId));

            return stateCell?.value.toLowerCase() === "in progress" && assigneeCell?.value === selectedAssignee;
        });

        setInProgressTasksCount(inProgressTasks.length);
    };

    // When a teammate is selected, calculate their tasks
    useEffect(() => {
        if (selectedAssignee) {
            calculateInProgressTasks();
        }
    }, [selectedAssignee, data, columns]);

    // Filter out selected teammate's "In Progress" tasks from Kanban board
    const getFilteredKanbanData = () => {
        if (!data || !data.rows) return data;

        // Filter out only the selected assignee's "In Progress" tasks from Kanban board
        const filteredRows = data.rows.filter(row => {
            const assigneeCell = row.cells.find(cell => cell.columnId === assigneeColumnId);
            const stateCell = row.cells.find(cell => cell.columnId === stateColumnId);

            // Keep all tasks except the "In Progress" tasks of the selected assignee
            return !(assigneeCell?.value === selectedAssignee && stateCell?.value.toLowerCase() === 'in progress');
        });

        return { ...data, rows: filteredRows };
    };

    const onDragEnd = async (result) => {
        const { destination, draggableId } = result;

        if (!destination) return;

        const draggedTask = data.rows.find(task => String(task.id) === draggableId);

        if (!draggedTask) return;

        const stateCell = draggedTask.cells.find(cell => cell.columnId === stateColumnId);
        const assigneeCell = draggedTask.cells.find(cell => cell.columnId === assigneeColumnId);

        const droppedOnTeammate = uniqueAssignees.find(assignee => assignee.name === destination.droppableId);

        if (droppedOnTeammate && assigneeCell.value !== destination.droppableId) {
            // Optimistically update the UI
            const previousAssignee = assigneeCell.value;
            assigneeCell.value = destination.droppableId;

            const updatedTask = { ...draggedTask, cells: [...draggedTask.cells] };
            setData(prevData => ({
                ...prevData,
                rows: prevData.rows.map(task => task.id === draggedTask.id ? updatedTask : task),
            }));

            try {
                await fetch(`${AUTH_BASE_URL}projects/planning/update-state`, {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify({
                        taskId: draggedTask.id,
                        newAssignee: destination.droppableId,
                        columnId: assigneeColumnId,
                    }),
                });
            } catch (error) {
                console.error('Error updating assignee in Smartsheet:', error);
                // Rollback the UI if the request fails
                assigneeCell.value = previousAssignee;
                setData(prevData => ({
                    ...prevData,
                    rows: prevData.rows.map(task => task.id === draggedTask.id ? draggedTask : task),
                }));
            }
        } else if (stateCell.value !== destination.droppableId) {
            const previousState = stateCell.value;
            stateCell.value = destination.droppableId;

            const updatedTask = { ...draggedTask, cells: [...draggedTask.cells] };
            setData(prevData => ({
                ...prevData,
                rows: prevData.rows.map(task => task.id === draggedTask.id ? updatedTask : task),
            }));

            try {
                await fetch(`${AUTH_BASE_URL}projects/planning/update-state`, {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify({
                        taskId: draggedTask.id,
                        newState: destination.droppableId,
                        columnId: stateColumnId,
                    }),
                });
            } catch (error) {
                console.error('Error updating task state in Smartsheet:', error);
                stateCell.value = previousState;
                setData(prevData => ({
                    ...prevData,
                    rows: prevData.rows.map(task => task.id === draggedTask.id ? draggedTask : task),
                }));
            }
        }
    };

    let borderStyle = {
        borderWidth: '4px',
        borderStyle: 'solid',
        borderRadius: '8px'
    };

    const [startColor, endColor] = getGradientColors(selectedAssignee?.toLowerCase());

    return (
        <Wrapper>
            <DragDropContext onDragEnd={onDragEnd}>
                <div className="main-content pt-0">
                    <div className="container-fluid">
                        <div className="inner-body users">
                            <div
                                className="page-header d-flex justify-content-between align-items-center flex-direction-column"
                                style={{
                                    boxShadow: selectedAssignee
                                        ? '0px 20px 45px rgba(0, 0, 0, 0.1)'
                                        : 'none',
                                    borderImage: selectedAssignee === selectedAssignee
                                        ? `linear-gradient(${startColor}, ${endColor}) 1`
                                        : 'none',
                                    borderLeft: selectedAssignee ? '20px solid' : '0',
                                    flexDirection: 'column',
                                    backgroundColor: selectedAssignee ? '#ffffff' : '#f2f2f2',
                                    padding: '40px',
                                    paddingBottom: selectedAssignee ? '40px' : '0',
                                    marginBottom: '0',
                                }}
                            >
                                <div
                                    style={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        alignItems: 'flex-start',
                                        width: '100%',
                                        marginBottom: '10px',
                                    }}
                                >
                                    <h2 className="main-content-title tx-24 mg-b-5">
                                        {selectedAssignee
                                            ? `${selectedAssignee}'s In Progress Tasks (${inProgressTasksCount})`
                                            : (
                                                <>
                                                    Project Planning - Sprint {currentSprint}{' '}
                                                    <span style={{ fontSize: '16px' }}>
                                                    {sprintStartDate?.toLocaleDateString()} - {sprintEndDate?.toLocaleDateString()}
                                                </span>
                                                </>
                                            )}
                                    </h2>
                                </div>
                                <div
                                    style={{
                                        display: 'flex',
                                        justifyContent: 'flex-end',
                                        alignItems: 'center',
                                        width: '100%',
                                    }}
                                >
                                    <Team
                                        assignees={uniqueAssignees}
                                        selectedAssignee={selectedAssignee}
                                        onSelectAssignee={setSelectedAssignee}
                                    />
                                </div>
                                <TeamStatus
                                    selectedAssignee={selectedAssignee}
                                    data={data}
                                    columns={columns}
                                    fetchData={fetchData}
                                />
                            </div>

                            {!data ? (
                                <div>Loading...</div>
                            ) : (
                                <div className="row row-sm">
                                    <KanbanBoard
                                        data={getFilteredKanbanData()} // Pass the filtered Kanban data here
                                        onDragEnd={onDragEnd}
                                        stateColumnId={stateColumnId}
                                        assigneeColumnId={assigneeColumnId}
                                        fetchData={fetchData}
                                        setData={setData}
                                        selectedAssignee={selectedAssignee}
                                    />
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </DragDropContext>
        </Wrapper>
    );
};

export default Planning;
