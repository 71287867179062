// Fibonacci levels mapped to capacity percentages
const fibonacciToPercentage = {
    1: 5,
    2: 10,
    3: 15,
    5: 25,
    8: 40,
    13: 75,
    21: 100
};

export const calculateCapacity = (assignee) => {
    const totalTasks = assignee.tasks.length;

    // Return 0 if no tasks are assigned
    if (totalTasks === 0) {
        return 0;
    }

    const currentDate = new Date();
    const twoWeeks = 14 * 24 * 60 * 60 * 1000; // Two weeks in milliseconds
    const oneWeek = 7 * 24 * 60 * 60 * 1000;   // One week in milliseconds

    // Define a multiplier based on the days until the task is due
    const fibonacciMultiplier = (daysUntilDue) => {
        if (daysUntilDue <= 0) return 2.5;    // Overdue tasks, increase priority significantly
        if (daysUntilDue <= 3) return 2.0;    // Less than 3 days, very high priority
        if (daysUntilDue <= 7) return 1.75;   // Less than a week, high priority
        if (daysUntilDue <= 10) return 1.5;   // Between 7 and 10 days
        if (daysUntilDue <= 14) return 1.25;  // Between 10 and 14 days
        if (daysUntilDue <= 21) return 1.0;   // Between 14 and 21 days
        if (daysUntilDue <= 30) return 0.75;  // Between 21 and 30 days
        return 0.625;                         // More than 30 days, reduced priority
    };

    // Sum the difficulty levels of all tasks, factoring in the due date
    const totalCapacity = assignee.tasks.reduce((acc, task) => {
        const effortCell = task.cells.find(cell => cell.columnId === assignee.effortColumn);
        const dueDateCell = task.cells.find(cell => cell.columnId === assignee.endColumn); // Use assignee.endColumn

        if (effortCell && effortCell.value && dueDateCell && dueDateCell.value) {
            const difficulty = parseInt(effortCell.value, 10);
            const dueDate = new Date(dueDateCell.value); // Convert 'YYYY-MM-DD' to Date object

            // Check if the difficulty level has a corresponding capacity percentage
            if (fibonacciToPercentage[difficulty]) {
                let taskCapacity = fibonacciToPercentage[difficulty];

                // Calculate how far the due date is, in days
                const daysUntilDue = (dueDate - currentDate) / (1000 * 60 * 60 * 24); // Convert ms to days

                // Get the multiplier based on how far the due date is
                const multiplier = fibonacciMultiplier(daysUntilDue);

                // Apply the multiplier to the task's capacity
                taskCapacity *= multiplier;

                // Add the task capacity to the total
                acc += taskCapacity;
            }
        }
        return acc;
    }, 0);

    // Ensure the total capacity does not exceed 100%
    return totalCapacity
};
